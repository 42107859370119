import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ImageEdito = ({ src, ...props }) => {
    const data = useStaticQuery(graphql`
        query {
            allFile(
                filter: {
                    relativeDirectory: { eq: "images/edito" }
                    internal: { mediaType: { regex: "/image/" } }
                }
            ) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            fluid(
                                pngCompressionSpeed: 10
                                pngQuality: 10
                                jpegQuality: 10
                                quality: 10
                                jpegProgressive: true
                                maxWidth: 1050
                            ) {
                                ...GatsbyImageSharpFluid
                                ...GatsbyImageSharpFluidLimitPresentationSize
                            }
                        }
                    }
                }
            }
        }
    `)

    const match = useMemo(
        () =>
            data.allFile.edges.find(
                ({ node }) => src === `${node.name}.${node.extension}`
            ),
        [data, src]
    )

    if (match) {
        return <Img fluid={match.node.childImageSharp.fluid} {...props} />
    }
}

export default ImageEdito
