import React, { Component } from "react"

// Styles
import Styles from "./Wysiwyg.module.styl"

class Wysiwyg extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={Styles.Wysiwyg}>
                <div className={Styles.Wysiwyg__inner}>
                    <div
                        className={`${Styles.Wysiwyg__rte} rte`}
                        dangerouslySetInnerHTML={{ __html: this.props.data }}
                    ></div>
                </div>
            </div>
        )
    }
}

export default Wysiwyg
