import React, { Component } from "react"

// Styles
import Styles from "./ImageFull.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import ImageEdito from "~components/Images/ImageEdito"

class ImageFull extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={Styles.ImageFull}>
                <div className={Styles.ImageFull__inner}>
                    <ImageEdito
                        src={this.props.data}
                        className={`${Styles.ImageFull__img}`}
                    />
                </div>
            </div>
        )
    }
}

export default ImageFull
