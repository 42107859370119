import React, { Component } from "react"

// Styles
import Styles from "./Quote.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"

class Quote extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={Styles.Quote}>
                <div className={Styles.Quote__inner}>
                    <h2 className={`quote`}>{this.props.data}</h2>
                </div>
            </div>
        )
    }
}

export default Quote
