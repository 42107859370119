import React, { Component } from "react"
import Helmet from "react-helmet"

class Metas extends Component {
    render() {
        return (
            <Helmet>
                {this.props.title && <title>{this.props.title}</title>}
                {this.props.title && (
                    <meta
                        itemprop="name"
                        content={`${this.props.title} - Le bon site, par Spintank`}
                    />
                )}
                {this.props.title && (
                    <meta
                        property="og:title"
                        content={`${this.props.title} - Le bon site, par Spintank`}
                    />
                )}
                {this.props.title && (
                    <meta
                        name="twitter:title"
                        content={`${this.props.title} - Le bon site, par Spintank`}
                    />
                )}

                {this.props.description && (
                    <meta name="description" content={this.props.description} />
                )}
                {this.props.description && (
                    <meta
                        itemprop="description"
                        content={this.props.description}
                    />
                )}
                {this.props.description && (
                    <meta
                        property="og:description"
                        content={this.props.description}
                    />
                )}
                {this.props.description && (
                    <meta
                        name="twitter:description"
                        content={this.props.description}
                    />
                )}

                {!this.props.shouldIndex && (
                    <meta name="robots" content="noindex" />
                )}
            </Helmet>
        )
    }
}

export default Metas
