import React, { Component } from 'react';
import { Link } from 'gatsby';

// Components
import Icon from "~icons/Icon"

class CTA extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Link
                to={`${this.props.data.href}`}
                className={`btn btn--primary`}
            >
                <span className={`btn__text`}>
                    {this.props.data.label}
                </span>
                <div className={`btn--primary__right`}>
                    <Icon
                        name={`big-arrow-right`}
                        width={48}
                        height={48}
                        fill="#000"
                    />
                    <Icon
                        name={`big-arrow-right`}
                        width={48}
                        height={48}
                        fill="#000"
                    />
                </div>
            </Link>
        );
    }
}

export default CTA
