import React, { Component } from "react"

// Styles
import Styles from "./PanelEdito.module.styl"
import CommonStyles from "./PanelCommons.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import Wysiwyg from "~components/Edito/Wysiwyg"
import Quote from "~components/Edito/Quote"
import ImageFull from "~components/Edito/ImageFull"
import ImageCenter from "~components/Edito/ImageCenter"
import CTA from "~components/Edito/CTA"

class PanelEdito extends Component {
    constructor(props) {
        super(props)
    }

    renderBlocksFromData() {
        const Blocks = this.props.blocks.map((block, index) => {
            switch (block.type) {
                case "wysiwyg":
                    return (
                        <div
                            className={CommonStyles.PanelItem}
                            key={`edito-block-wysiwyg-${index}`}
                        >
                            <Wysiwyg data={block.content} />
                        </div>
                    )
                    break

                case "imageFull":
                    return (
                        <div
                            className={CommonStyles.PanelItem}
                            key={`edito-block-imagefull-${index}`}
                        >
                            <ImageFull data={block.src} />
                        </div>
                    )
                    break

                case "imageCenter":
                    return (
                        <div
                            className={CommonStyles.PanelItem}
                            key={`edito-block-imagecenter-${index}`}
                        >
                            <ImageCenter data={block.src} />
                        </div>
                    )
                    break

                case "quote":
                    return (
                        <div
                            className={CommonStyles.PanelItem}
                            key={`edito-block-quote-${index}`}
                        >
                            <Quote data={block.content} />
                        </div>
                    )
                    break

                case "cta":
                    return (
                        <div
                            className={CommonStyles.PanelItem}
                            key={`edito-block-cta-${index}`}
                        >
                            <CTA data={block} />
                        </div>
                    )
                    break
            }
        })

        return Blocks
    }

    render() {
        const Blocks = this.renderBlocksFromData()

        return (
            <div className={Styles.PanelEdito}>
                <Row>
                    <Cell start="1" end="11">
                        <div className={Styles.PanelEdito__inner}>{Blocks}</div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export default PanelEdito
