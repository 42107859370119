import React, { Component } from "react"
import { Link } from "gatsby"

// Layout
import { Row, Cell, View, Panel, Footer } from "~layout/Layout"

// Components
import PanelTitle from "~components/Panels/Edito/PanelTitle"
import PanelEdito from "~components/Panels/Edito/PanelEdito"
import CanvasBackground from "~components/Canvases/CanvasBackground"
import Supfooter from "~components/Supfooters/Supfooter"
import Metas from "~misc/Metas"

class EditoPage extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <View name="edito" theme="edito">
                <Metas
                    title={`${this.props.pageContext.title}`}
                    description={this.props.pageContext.seo.description}
                    image={this.props.pageContext.seo.card}
                    shouldIndex={true}
                />

                <CanvasBackground />
                <PanelTitle title={this.props.pageContext.title} />
                <PanelEdito blocks={this.props.pageContext.blocks} />

                <div className={`view__bottom`}>
                    <Supfooter />
                    <Footer />
                </div>
            </View>
        )
    }
}

export default EditoPage
