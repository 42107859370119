import React, { Component } from "react"

// Styles
import Styles from "./CanvasBackground.module.styl"

// Helpers
import NumberUtils from "~helpers/numberUtils"
import SptkUtils from "~helpers/sptk"
import { debounce } from "~helpers/functions"

// Libs
const random = require("canvas-sketch-util/random")

class CanvasBackground extends Component {
    constructor(props) {
        super(props)

        this.canvasWrapper = React.createRef()
        this.canvas = React.createRef()
        this.canvasItem = React.createRef()

        this.onResize = this.onResize.bind(this)
        this.bindScroll = debounce(this.onScroll.bind(this), 50)
        this.onNavOpen = this.onNavOpen.bind(this)
        this.onNavClose = this.onNavClose.bind(this)
    }

    componentWillMount() {}

    componentDidMount() {
        if (this.props.timeline) {
            this.props.timeline.add(
                {
                    targets: this.canvas.current,
                    opacity: [0, 1],
                    duration: 800,
                    easing: "easeInOutQuad",
                },
                2300
            )
        }

        this.createCanvas()
        this.setCanvasSizes()
        this.bindEvents()
        this.draw()
    }

    componentWillUnmount() {
        this.unbindEvents()
        this.undraw()
    }

    bindEvents() {
        window.addEventListener("resize", this.onResize)
        window.addEventListener("scroll", this.bindScroll)
        document.addEventListener("nav:open", this.onNavOpen)
        document.addEventListener("nav:close", this.onNavClose)
    }

    unbindEvents() {
        window.removeEventListener("resize", this.onResize)
        window.removeEventListener("scroll", this.bindScroll)
        document.removeEventListener("nav:open", this.onNavOpen)
        document.removeEventListener("nav:close", this.onNavClose)
    }

    onNavOpen() {
        this.undraw()
    }

    onNavClose() {
        this.checkCanvasPosition()
    }

    onScroll() {
        this.checkCanvasPosition()
    }

    checkCanvasPosition() {
        if (!this.canvasWrapper || this.canvasWrapper.current === null) {
            return
        }

        // This is not working correctly, this should be fixed
        const canvasBottom = this.canvas.current.getBoundingClientRect().bottom

        if (canvasBottom < 0) {
            if (this.raf) {
                cancelAnimationFrame(this.raf)
                this.raf = null
            }
        } else {
            if (!this.raf) {
                this.raf = requestAnimationFrame(this.draw.bind(this))
            }
        }
    }

    createCanvas() {
        this.ratio = window.devicePixelRatio
        this.ctx = this.canvas.current.getContext("2d")
    }

    setCanvasSizes() {
        this.canvas.current.width = window.innerWidth * this.ratio
        this.canvas.current.height = window.innerHeight * 1.5 * this.ratio

        this.canvas.current.style.width = `${window.innerWidth}px`
        this.canvas.current.style.height = `${window.innerHeight * 1.5}px`

        this.width = this.canvas.current.width / this.ratio
        this.height = this.canvas.current.height / this.ratio

        this.radiusW = this.width / 2
        this.radiusH = this.height / 2

        const device = new SptkUtils()

        this.strokeWidth = 1

        if (device.utils.isMobileTablet) {
            this.strokeWidth = 0.5
        }
    }

    onResize() {
        this.setCanvasSizes()
    }

    undraw() {
        cancelAnimationFrame(this.raf)
        this.raf = null
    }

    draw() {
        const time = Date.now() / 4000

        this.ctx.save()
        // SCALE RATIO FOR DEVICE
        this.ctx.scale(this.ratio, this.ratio)
        this.ctx.clearRect(0, 0, this.width, this.height)

        this.ctx.lineWidth = this.strokeWidth
        const totalLines = 150
        //DRAW THE SHAPE
        this.ctx.translate(this.width / 2, this.height / 2)
        this.ctx.scale(this.scaleShapeTransition, this.scaleShapeTransition)
        this.ctx.strokeStyle = "#000"

        for (let i = 0; i < totalLines; i++) {
            this.ctx.beginPath()
            const angle = NumberUtils.mapRange(i, 0, totalLines, 0, 2 * Math.PI)

            const x1 = Math.cos(angle) * (this.radiusW / 4)
            const y1 = Math.sin(angle) * (this.radiusH / 4)

            //CALC OF NOISE VALUE
            const nx = x1 / this.width - 0.5
            const ny = y1 / this.height - 0.5
            let n =
                1 * random.noise2D(4.6 * nx, 3.6 * ny + time) +
                0.5 * random.noise2D(2 * nx, 2 * ny + time) +
                0.25 * random.noise2D(4 * nx, 2 * ny + time)

            //SIZE OF BLOB LINES (VARYING BETWEEN 250 & 300)
            const scalar = NumberUtils.mapRange(
                n,
                -1,
                1,
                -this.width / 5,
                this.width / 5
            )

            const x2 = Math.cos(angle) * (this.radiusW + scalar)
            const y2 = Math.sin(angle) * (this.radiusH + scalar)

            //DRAW EACH LINE OF THE BLOB
            this.ctx.moveTo(x1, y1)
            this.ctx.lineTo(x1, y1)
            this.ctx.lineTo(x2, y2)
            this.ctx.closePath()

            this.ctx.stroke()
        }

        this.ctx.restore()

        this.raf = requestAnimationFrame(this.draw.bind(this))
    }

    render() {
        return (
            <div className={Styles.CanvasBackground} ref={this.canvasItem}>
                <div
                    className={`${Styles.CanvasBackground__canvas}`}
                    ref={this.canvasWrapper}
                >
                    <canvas
                        className={`${Styles.CanvasBackground__canvas__item}`}
                        ref={this.canvas}
                    ></canvas>
                </div>
            </div>
        )
    }
}

export default CanvasBackground
